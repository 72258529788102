import { Swiper, Pagination, Navigation, Autoplay, FreeMode } from 'swiper';
export class swipercarousel {
    static init() {
        let $carousels = $('.swiper');
        $carousels.each((index, element) => {
            let userOptions, pagerOptions;
            if (element.dataset.swiperOptions != undefined) {
                userOptions = JSON.parse(element.dataset.swiperOptions);
            }
            // Pager
            if (userOptions.pager) {
                pagerOptions = {
                    pagination: {
                        el: userOptions.pagination.el,
                        clickable: true,
                        bulletActiveClass: 'active',
                        bulletClass: 'page-item',
                        renderBullet: function (index, className) {
                            return '<li class="' + className + '"><a href="#" class="page-link btn-icon btn-xs fs-xs">' + (index + 1) + '</a></li>';
                        }
                    }
                };
            }
            // Slider init
            Swiper.use([Navigation, Pagination, Autoplay, FreeMode]);
            let options = { ...userOptions, ...pagerOptions };
            console.log(options);
            let swiper = new Swiper(element, options);
            //swiper.pagination.render();
        });
    }
}
